import _styled from "styled-components";
import { isMobileOnly } from 'react-device-detect';
import { Background } from '../Background';
import { BackgroundVariant } from '../Background/Background';
import ActiveModal from '../modals/ActiveModal/ActiveModal';
import { Nav } from '../Nav';
import useAcceptOrgInvite from '~/hooks/useAcceptOrgInvite';
import useCheckClientVersionOutdated from '~/hooks/useCheckClientVersionOutdated';
import { Toast } from '~/hooks/useToast';
interface LayoutProps {
  children: React.ReactElement;
  backgroundVariant?: BackgroundVariant;
}
const LayoutContainer = _styled.div({
  "display": "flex",
  "height": "100dvh",
  "width": "100vw",
  "overflow": "hidden",
  "padding": "0.5rem"
});
const MainContainer = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "overflow": "hidden",
  "borderRadius": "0.5rem",
  "borderWidth": "1px",
  "borderColor": "#6761fa5e"
});
const ContentWrapper = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "flexDirection": "column"
});
const NavWrapper = _styled.div({
  "display": "flex"
});
const ActiveModalContainer = _styled.div({
  "display": "flex",
  "flex": "1 1 0%",
  "flexDirection": "column",
  "overflowY": "auto"
});
const Layout = ({
  children,
  backgroundVariant = 'default'
}: LayoutProps) => {
  // Hooks
  useCheckClientVersionOutdated();
  // Hook that monitors the URL for an org invite and accepts it
  useAcceptOrgInvite();
  return <LayoutContainer>
      <MainContainer>
        <ContentWrapper>
          {!isMobileOnly && <NavWrapper>
              <Nav />
              <Toast />
            </NavWrapper>}
          <Background variant={backgroundVariant} />
          <ActiveModalContainer>
            {children}
            <ActiveModal />
          </ActiveModalContainer>
        </ContentWrapper>
      </MainContainer>
    </LayoutContainer>;
};
export default Layout;